import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComService {

  constructor() {
  }

  private drawer = new BehaviorSubject('');
  drawerObservable = this.drawer.asObservable();

  private header = new BehaviorSubject('');
  headerObservable = this.header.asObservable();

  private response = new BehaviorSubject(null);
  responseObservable = this.response.asObservable();

  private loading = new BehaviorSubject(false);
  loadingObservable = this.loading.asObservable();

  ToggleDrawer(): void {
    this.drawer.next('1');
  }

  ToggleHeader(): void {
    this.header.next('1');
  }

  UpdateResponse(message: object): void {
    this.response.next(message);
  }

  UpdateLoading(message: boolean): void {
    this.loading.next(message);
  }
}
