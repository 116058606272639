<div class="info-container">
  <h3><b>XML Integration</b></h3>
  <p>This website describes the integration of SmartDocuments and a third-party system based on webservices, by which
    the third-party system starts the generation of a document through an XML message.</p>

  <p>This XML message, the so-called Start-XML, is deposited at the SmartDocuments Deposit Service. The SOAP 1.1 web
    service accepts the Start-XML and returns a ticket. This ticket is an encrypted reference to the deposited
    Start-XML. The ticket is only valid for a limited period.</p>

  <p>The deposit service is a secure service that requires authentication. The user to be authenticated has to be known
    in SmartDocuments with username and password. With this user the third-party system can deposit a Start-XML for a
    different user (who also needs to be known in SmartDocuments). This second user would be the equivalent of the
    current user who is active in the third-party system. The first user would be a special integration user
    specifically used for accessing the deposit service.</p>

  <p>Next, the third-party system can start the SmartDocuments Web Wizard. This wizard is a web application, and the
    ticket can be added as a parameter to its URL. The ticket will provide for the authentication of the user for whom
    the deposit was done. The wizard will use the ticket to retrieve the Start-XML from the deposit and start the
    document generation process.</p>
</div>
<div class="info-container">
  <h3><b>SOAP Request</b></h3>
  <p>The webservice that can be used to deposit the XML to is called ‘DocumentCreationService’ and it will need to receive a SOAP message.</p>

  The body of the SOAP message contains:
  <ul>
  <li>mode: whether the document should be created with or without using the wizard</li>

  <li>username: the username of the actual user that the document will be created with</li>

  <li>xmlData: the customer data in xml format, including the SmartDocument node used to select the correct template</li>
  </ul>
  Available modes to choose from:
<ul>
  <li>UNATTENDED: document will be generated without using a Wizard.
  If a user with the specified username does not exist, an error is returned.</li>

  <li>WIZARD: document will be generated using the Wizard, without the user having to login.
    If a user with the specified username does not exist, an error is returned.</li>

  <li>WIZARD_NO_AUTH: the wizard will be user, but the user will have to login in the wizard.
    If the given user name exists, only this user can pick up the XML. Otherwise: any user can pick it up.</li>

  <li>DETECT: if the template contains questions that will be asked in the Wizard, the Wizard will be used. Otherwise the
  document will be generated in an unattended way.
    If a user with the specified username does not exist, an error is returned.</li>

  <li>DETECT_NO_AUTH: if the template contains questions that will be asked in the Wizard, the Wizard will be used.
  Otherwise the document will be generated in an unattended way.
    If the given user name exists, only this user can pick up the XML. Otherwise: any user can pick it up.</li></ul>
</div>
<div class="info-container">
  <h3><b>Starting the wizard</b></h3>
  <p>If the call to the webservice was successfull, an XML will be returned containing a ticket.</p>
<p>Next the wizard needs to be started with a URL similar to the following, containing the received ticket:
  "https://partners.smartdocuments.com/smartdocuments/wizard/?ticket=[RECEIVED_TICKET]"</p>

  <p>The wizard will be opened and the user with the username from the SOAP header will be logged in, the template will be selected and the data from the XML deposit will be available to the template.</p>
</div>
