import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {ComService} from './services/com.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
  title = 'developmentpage';
  @ViewChild('drawer') infoDrawer: MatDrawer;

  constructor(
    private com: ComService
  ) {
  }

  ngAfterViewInit(): void {
    this.com.drawerObservable.subscribe(message => {
      if (message !== '') {
        this.infoDrawer.toggle();
      }
    });
  }
}
