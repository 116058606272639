import {Component, OnInit} from '@angular/core';
import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {ApiService} from '../services/api.service';
import {ComService} from '../services/com.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  })
export class RequestComponent implements OnInit {

  newDate;
  header = false;
  animationState = 'out';
  awaitingRequest = false;
  showHeader = false;

  mode = 'DETECT';
  username = 'testuser';
  typistid = '2A2F6DA18D8844D4A3FF0BC22036C652';
  usergroupid = '7F2C950AE2A74365AEA86FD9214AD01F';
  authorid = '2A2F6DA18D8844D4A3FF0BC22036C652';
  templategroupid = 'F8BA363058484965B722C6D3B0D76C6C';
  templateid = '6DAE0879AD9144B7B127A9D907C4C0F5';

  constructor(
    private api: ApiService,
    private com: ComService
  ) {
  }

  ngOnInit(): void {
    this.newDate = new Date();
  }

  ToggleHeader(): void {
    this.header = !this.header;
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
  }

  SendRequest(): void {
    this.awaitingRequest = true;
    this.com.UpdateLoading(true);
    this.com.UpdateResponse({
      status: null,
      message: null,
      explanation: null,
      xml: null
    });
    this.api.SendToSD(
      this.newDate,
      this.mode,
      this.username,
      this.typistid,
      this.usergroupid,
      this.authorid,
      this.templategroupid,
      this.templateid)
      .then(
        () => {
          this.com.UpdateLoading(false);
          this.awaitingRequest = false;
        },
        () =>  {
          this.com.UpdateLoading(false);
          this.awaitingRequest = false;
        }
      );
  }
}
