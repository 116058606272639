<div class="request-code">
  <div class="code-div">
    &lt;soapenv:Envelope&gt;
  </div>
  <div class="code-div header" [ngClass]="{'visible-header': showHeader}">
    <span style="--pretab: '1'">&lt;soapenv:Header&gt;</span><br>
    <span style="--pretab: '2'">&#x9;&lt;wsse:Security xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd"
    xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd"&gt;</span><br>
    <span style="--pretab: '3'">&lt;wsse:UsernameToken wsu:Id="UsernameToken-58C28EB8A75BB5FAD715383849420913"&gt;</span><br>
    <span style="--pretab: '4'">&lt;wsse:Username&gt;</span><br>
    <span style="--pretab: '5'">testuser</span><br>
    <span style="--pretab: '4'">&lt;/wsse:Username&gt;</span><br>
    <span style="--pretab: '4'">&lt;wsse:Password Type="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordText"&gt;</span><br>
    <span style="--pretab: '5'">7_GStaS?w!3QsGkK</span><br>
    <span style="--pretab: '4'">&lt;/wsse:Password&gt;</span><br>
    <span style="--pretab: '4'">&lt;wsse:Nonce EncodingType="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-soap-message-security-1.0#Base64Binary"&gt;</span><br>
    <span style="--pretab: '5'">1s2Smvgbrknn0Jb+6wYGlg==</span><br>
    <span style="--pretab: '4'">&lt;/wsse:Nonce&gt;</span><br>
    <span style="--pretab: '4'">&lt;wsu:Created&gt;</span><br>
    <span style="--pretab: '5'">{{this.newDate | date: 'yyyy-mm-ddTHH:MM:ss.SSS'}}Z</span><br>
    <span style="--pretab: '4'">&lt;/wsu:Created&gt;</span><br>
    <span style="--pretab: '3'">&lt;/wsse:UsernameToken&gt;</span><br>
    <span style="--pretab: '2'">&lt;/wsse:Security&gt;</span><br>
    <span style="--pretab: '1'">&lt;/soapenv:Header&gt;</span><br>
  </div>
  <div class="code-div">
    <span style="--pretab: '1'">&lt;soapenv:Body&gt;</span><br>
    <span style="--pretab: '2'">&lt;v1:create&gt;</span><br>
    <span style="--pretab: '3'">&lt;mode&gt;<input class="code-inputfield-mode code-inputfield" [(ngModel)]="mode"/>&lt;/mode&gt;</span><br>
    <span style="--pretab: '3'">&lt;username&gt;<input class="code-inputfield-un code-inputfield" [(ngModel)]="username"/>&lt;/username&gt;</span><br>
    <span style="--pretab: '3'">&lt;xmlData&gt;</span><br>
    <span style="--pretab: '4'">&lt;![CDATA[</span><br>
    <span style="--pretab: '5'">&lt;Root&gt;</span><br>
    <span style="--pretab: '6'">&lt;CustomerData&gt;…&lt;/CustomerData&gt;</span><br>
    <span style="--pretab: '6'">&lt;SmartDocument&gt;</span><br>
    <span style="--pretab: '7'">&lt;Selection&gt;</span><br>
    <span style="--pretab: '8'">&lt;TypistID&gt;<input class="code-inputfield-ID code-inputfield" [(ngModel)]="typistid"/>&lt;/TypistID&gt;</span><br>
    <span style="--pretab: '8'">&lt;UserGroupID&gt;<input class="code-inputfield-ID code-inputfield" [(ngModel)]="usergroupid"/>&lt;/UserGroupID&gt;</span><br>
    <span style="--pretab: '8'">&lt;AuthorID&gt;<input class="code-inputfield-ID code-inputfield" [(ngModel)]="authorid"/>&lt;/AuthorID&gt;</span><br>
    <span style="--pretab: '8'">&lt;TemplateGroupID&gt;<input class="code-inputfield-ID code-inputfield" [(ngModel)]="templategroupid"/>&lt;/TemplateGroupID&gt;</span><br>
    <span style="--pretab: '8'">&lt;TemplateID&gt;<input class="code-inputfield-ID code-inputfield" [(ngModel)]="templateid"/>&lt;/TemplateID&gt;</span><br>
    <span style="--pretab: '7'">&lt;/Selection&gt;</span><br>
    <span style="--pretab: '6'">&lt;/SmartDocument&gt;</span><br>
    <span style="--pretab: '5'">&lt;/Root&gt;</span><br>
    <span style="--pretab: '4'">]]&gt;</span><br>
    <span style="--pretab: '3'">&lt;/xmlData&gt;</span><br>
    <span style="--pretab: '2'">&lt;/v1:create&gt;</span><br>
    <span style="--pretab: '1'">&lt;/soapenv:Body&gt;</span><br>
    <span style="--pretab: '0'">&lt;/soapenv:Envelope&gt;</span><br>
  </div>
</div>
<div class="request-footer">
  <span>Request</span>
  <span class="headerToggle">
    <span class="toggleLabel">Show SOAP-header</span>
    <mat-slide-toggle color="primary" (toggleChange)="showHeader = !showHeader" ></mat-slide-toggle>
  </span>
  <button mat-flat-button color="primary" (click)="SendRequest()" [disabled]="awaitingRequest">
    <span>RUN</span>
    <mat-icon>play_circle</mat-icon>
  </button>
</div>
