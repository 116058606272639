<div *ngIf="showLoading" class="loading-container">
  <mat-spinner></mat-spinner>
</div>
<div class="response-code">
  <div class="pre-area" *ngIf="!showLoading">
  <pre class="code-pre" [ngClass]="messageState">{{message}}</pre>
  <br>
  <pre class="code-pre" *ngIf="ticket">
Message was successfully sent and the server sent a ticket back!

Ticket:</pre>
<pre class="code-pre warn" *ngIf="ticket">{{ticket}}</pre>
<br>
<pre class="code-pre" *ngIf="error">
Message was successfully sent but the server returned an error!

Error message:</pre>
<pre class="code-pre warn" *ngIf="error">{{error}}</pre>
<br>
<pre class="code-pre" *ngIf="error">Explanation:</pre>
<pre class="code-pre warn" *ngIf="error">{{explanation}}</pre>
</div>
</div>
<pre class="code-pre wizard" *ngIf="ticket && !showLoading">
↓ You can now open the wizard! ↓</pre>
<div class="response-footer">
  <span>Response</span>
  <button mat-flat-button color="primary" [disabled]="!ticket || showLoading" (click)="OpenWizard()">
    <span>OPEN WIZARD</span>
    <mat-icon>offline_pin</mat-icon>
  </button>
</div>
