import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ComService} from './com.service';
import {delay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = 'https://partners.smartdocuments.com/wsxmldeposit/DocumentCreationService';
  dataToSend = '';

  constructor(
    private http: HttpClient,
    private com: ComService
  ) {
  }

  async SendToSD(
    date: string,
    mode: string,
    username: string,
    typistid: string,
    usergroupid: string,
    authorid: string,
    templategroupid: string,
    templateid: string
  ): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'text/xml:charset=UTF-8',
      soapaction: ''
    });

    this.dataToSend =
      '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://documentcreationservice.smartdocuments.nl/v1">' +
      '<soapenv:Header>' +
      '<wsse:Security xmlns:wsse="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd" xmlns:wsu="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd">' +
      '<wsse:UsernameToken wsu:Id="UsernameToken-58C28EB8A75BB5FAD715383849420913">' +
      '<wsse:Username>testuser</wsse:Username>' +
      '<wsse:Password Type="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordText" >7_GStaS?w!3QsGkK</wsse:Password>' +
      '<wsse:Nonce EncodingType="http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-soap-message-security-1.0#Base64Binary" >1s2Smvgbrknn0Jb+6wYGlg==</wsse:Nonce>' +
      '<wsu:Created>date</wsu:Created>' +
      '</wsse:UsernameToken>' +
      '</wsse:Security>' +
      '</soapenv:Header>' +
      '<soapenv:Body>' +
      '<v1:create>' +
      '<mode>' + mode + '</mode>' +
      '<username>' + username + '</username>' +
      '<xmlData>' +
      '<![CDATA[' +
      '<Root>' +
      '<CustomerData>...</CustomerData>' +
      '<SmartDocument>' +
      '<Selection>' +
      '<TypistID>' + typistid + '</TypistID>' +
      '<UserGroupID>' + usergroupid + '</UserGroupID>' +
      '<AuthorID>' + authorid + '</AuthorID>' +
      '<TemplateGroupID>' + templategroupid + '</TemplateGroupID>' +
      '<TemplateID>' + templateid + '</TemplateID>' +
      '</Selection>' +
      '</SmartDocument>' +
      '</Root>' +
      ']]>' +
      '</xmlData>' +
      '</v1:create>' +
      '</soapenv:Body>' +
      '</soapenv:Envelope>';

    return new Promise((resolve, reject) => {
      return this.http.post(
        this.apiUrl,
        this.dataToSend,
        {headers, responseType: 'text'})
        .toPromise()
        .then(
          res => {
            setTimeout(() => {
              const msg = res.substring(res.indexOf('<ticket>') + 8, res.indexOf('</ticket>'));
              this.com.UpdateResponse({
                status: 'success',
                message: msg,
                explanation: null,
                xml: res
              });
              resolve(res);
            }, 500);
          },
          (err) => {
            setTimeout(() => {
              const msgLong = err.error;
              const error = msgLong.substring(msgLong.indexOf('<faultstring>') + 13, msgLong.indexOf('</faultstring>'));
              const exp = msgLong.substring(msgLong.indexOf('<message>') + 9, msgLong.indexOf('</message>'));
              this.com.UpdateResponse({
                status: 'error',
                message: error,
                explanation: exp,
                xml: msgLong
              });
              reject(null);
            }, 500);
          });
    });
  }
}
