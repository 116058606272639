<mat-drawer-container class="app-container" autosize>
  <mat-drawer #drawer class="app-info-container" mode="side">
    <app-info></app-info>
  </mat-drawer>

  <div class="app-content">
    <app-header></app-header>
    <div class="code-screen">
      <app-request></app-request>
      <app-response></app-response>
    </div>
  </div>

</mat-drawer-container>
