import {Component, OnInit} from '@angular/core';
import {ComService} from '../services/com.service';
import beautify from 'xml-beautifier';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss']
})
export class ResponseComponent implements OnInit {

  messageState = 'warn';
  message = 'Please send the request';
  ticket = null;
  error = null;
  explanation = null;
  showLoading = false;
  apiUrl = 'https://partners.smartdocuments.com/smartdocuments/wizard/?ticket=';

  constructor(
    private com: ComService
  ) {
  }

  ngOnInit(): void {
    this.com.loadingObservable.subscribe(next => {
      this.showLoading = next;
    });

    this.com.responseObservable.subscribe(next => {
      if (next) {
        if (next.status === 'success') {
          this.messageState = next.status;
          this.message = beautify(next.xml);
          this.ticket = next.message;
          this.error = null;
          this.explanation = null;
        } else if (next.status === 'error') {
          this.messageState = next.status;
          this.message = beautify(next.xml);
          this.ticket = null;
          this.error = next.message;
          this.explanation = next.explanation;
        }
      }
    });
  }

  OpenWizard(): void {
    window.open(this.apiUrl + this.ticket, '_blank');
  }
}
