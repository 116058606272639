import {Component, OnInit} from '@angular/core';
import {ComService} from '../services/com.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private com: ComService
  ) {
  }

  ngOnInit(): void {
  }

  ToggleDrawer(): void {
    this.com.ToggleDrawer();
  }

}
